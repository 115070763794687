import { ContractCoverage, DeviceStatus } from '@/@types';
import { useDashboardDataContext } from '@/hooks';
import { SearchParams } from '../enums';
import { mapFilterCriteria } from '../utils';

/**
 * @description Custom hook to get filter data
 * Sets all available filters that will be shown in the filter sidebar.
 */
export const useFilterData = () => {
  const { availableDeviceTypes } = useDashboardDataContext();

  const getAvailableEndoscopes = (availableDeviceTypes: string[]) => {
    const availableDevices = availableDeviceTypes
      .filter(Boolean)
      .map((device) => ({
        key: device,
        value: device,
      }))
      .sort((a, b) => a.value.localeCompare(b.value));

    return {
      searchParam: SearchParams.DEVICES,
      title: 'dashboard.filters.mediaVideoEndoscopes',
      items: availableDevices,
    };
  };

  const getFilterItems = (availableDeviceTypes: string[]) => {
    const devices = getAvailableEndoscopes(availableDeviceTypes);
    return [
      {
        searchParam: SearchParams.STATUS,
        title: 'dashboard.filters.status',
        items: [
          {
            key: 'dashboard.statusCards.notInUse',
            value: DeviceStatus.NOT_IN_USE,
          },
          {
            key: 'dashboard.statusCards.inUse',
            value: DeviceStatus.IN_USE,
          },
          {
            key: 'dashboard.statusCards.errorDetected',
            value: DeviceStatus.ERROR_DETECTED,
          },
          {
            key: 'dashboard.statusCards.inRepair',
            value: DeviceStatus.IN_REPAIR,
          },
        ],
      },
      devices,
      {
        searchParam: SearchParams.LOANER,
        title: 'shared.loaner',
        items: [
          {
            key: 'dashboard.filters.loaner',
            value: 'true',
          },
        ],
      },
      {
        searchParam: SearchParams.SERVICE_CONTRACT,
        title: 'dashboard.filters.serviceContract',
        items: [
          {
            key: 'dashboard.filters.covered',
            value: ContractCoverage.COVERED,
          },
          {
            key: 'dashboard.filters.notCovered',
            value: ContractCoverage.NOT_COVERED,
          },
        ],
      },
    ];
  };

  const filterData = getFilterItems(availableDeviceTypes as string[]);
  const filterDataWithReducedValues = filterData.map((filter) => ({
    ...filter,
    items: filter.items.map((item) => ({
      ...item,
      value: mapFilterCriteria(item.value),
    })),
  }));

  return {
    filterData: filterDataWithReducedValues,
  };
};
