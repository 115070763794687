import { DataTestIds } from '@/@types';
import { Card } from '@/ui';
import { useTranslation } from 'react-i18next';
import { ErrorSolution, UserManualResponse } from '../interfaces';

interface ManualInstructionsCardProps {
  errorSolutions: ErrorSolution[];
  userManual?: UserManualResponse;
}

export const ManualInstructionsCard: React.FC<ManualInstructionsCardProps> = ({
  errorSolutions,
  userManual,
}) => {
  const { t } = useTranslation();

  // open user manual in new tab and on exact page number sent from the backend
  const handleOpenUserManual = () => {
    if (!userManual) {
      return;
    }

    window.open(
      `${userManual.url}#page=${userManual.troubleshootingPageNumber}`,
      '_blank'
    );
  };

  return (
    <Card
      className="flex flex-col gap-y-16 border-1 border-statusNotInUseBg md:p-16"
      dataTestId={DataTestIds.USER_MANUAL_INSTRUCTIONS_CONTAINER}
    >
      <h1 className="text-16_24 font-semibold text-text-base">
        {t('assetDetail.userManualInstructions')}
      </h1>
      {errorSolutions.map((solution) => (
        <div
          key={solution.solutions}
          className="rounded-8 bg-pageAlt p-16 text-text-base"
          data-testid={DataTestIds.USER_MANUAL_INSTRUCTIONS_TEXT_CONTAINER}
        >
          <p className="text-16_24">{solution.solutions} </p>
        </div>
      ))}
      <div className="flex flex-col gap-16 md:flex-row">
        <button
          className="rounded-4 bg-iconInfo px-16 py-12 text-14_16 font-semibold text-iconOnAccent"
          onClick={handleOpenUserManual}
          data-testid={DataTestIds.VIEW_USER_MANUAL_BUTTON}
        >
          {t('assetDetail.buttons.viewUserManual')}
        </button>
        <button
          className="rounded-4 border-1 border-button-secondaryIdleBorder bg-button-secondaryIdleBg px-16 py-12 text-14_16 font-semibold
        text-text-buttonSecondaryIdle"
          data-testid={DataTestIds.REQUEST_SERVICE_BUTTON}
        >
          {t('assetDetail.buttons.requestService')}
        </button>
      </div>
    </Card>
  );
};
