import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { BrowserHistoryOptions, createBrowserHistory } from 'history';
import { REACT_APP_INSIGHTS_CONNECTION } from '../configs/environment';

export const reactPlugin = new ReactPlugin();

/**
 * @description Initialize ApplicationInsights object and provide configuration for connecting to Azure Application Insights
 */
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: REACT_APP_INSIGHTS_CONNECTION,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: {
        history: createBrowserHistory({
          basename: '',
        } as BrowserHistoryOptions),
      },
    },
  },
});

/**
 * @description Initialize App Insights for tracking application performance and errors.
 * Initialize it only if REACT_APP_INSIGHTS_CONNECTION is set and the app is not running in development mode which is meant to be local environment.
 */
export function initAppInsights() {
  if (REACT_APP_INSIGHTS_CONNECTION && !import.meta.env.DEV) {
    appInsights.loadAppInsights();
  }
}
