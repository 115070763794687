/* eslint-disable @typescript-eslint/no-explicit-any */
import { Device } from '@/@types/interfaces/Device';
import { useDashboardDataContext, useDebounce } from '@/hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { SearchParams } from '../enums';
import { mapFilterCriteria } from '../utils';

const resolveMatch = (searchParam: string[], deviceParam: string) => {
  return (
    searchParam.length === 0 ||
    searchParam.includes(mapFilterCriteria(deviceParam))
  );
};

/**
 * @description Custom hook for searching and filtering devices.
 * Exposes searchTerm and setSearchTerm in order to show and filter data from useDashboardDataContext (all devices fetched at the beginning).
 */
export const useSearchDevices = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const { data } = useDashboardDataContext();
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const [searchParams] = useSearchParams();

  const statusParams = searchParams.get(SearchParams.STATUS)?.split(',') ?? [];
  const devicesParams =
    searchParams.get(SearchParams.DEVICES)?.split(',') ?? [];
  const loanerParams = searchParams.get(SearchParams.LOANER)?.split(',') ?? [];
  const serviceContractParams =
    searchParams.get(SearchParams.SERVICE_CONTRACT)?.split(',') ?? [];

  const searchDevices = (devices: Device[] | undefined) => {
    if (!devices) return [];

    const searchTermLowerCase = debouncedSearchTerm.toLowerCase();

    const filteredDevices = filterDevices(devices);

    const searchedTableData = filteredDevices.filter(
      (device) =>
        device.name.toLowerCase().includes(searchTermLowerCase) ||
        device.deviceType.toLowerCase().includes(searchTermLowerCase) ||
        t(`shared.deviceStatus.${device.deviceStatus.toLowerCase()}` as any)
          .toLowerCase()
          .includes(searchTermLowerCase) ||
        device.serialNumber.toLowerCase().includes(searchTermLowerCase) ||
        t(
          `dashboard.table.tableBody.${device.contractCoverage.toLowerCase()}` as any
        )
          .toLowerCase()
          .includes(searchTermLowerCase)
    );

    return searchedTableData;
  };

  const filterDevices = (devices: Device[]) => {
    const filteredDevices = devices.filter((device) => {
      const statusMatch = resolveMatch(statusParams, device.deviceStatus);
      const deviceMatch = resolveMatch(devicesParams, device.deviceType);
      const serviceContractMatch = resolveMatch(
        serviceContractParams,
        device.contractCoverage
      );

      const loanerMatch = loanerParams.length === 0 || device.isLoaner;

      return statusMatch && deviceMatch && loanerMatch && serviceContractMatch;
    });

    return filteredDevices;
  };

  return {
    searchTerm,
    setSearchTerm,
    searchedData: searchDevices(data),
    filterParamsCount:
      statusParams.length +
      devicesParams.length +
      loanerParams.length +
      serviceContractParams.length,
  };
};
