/**
 * @class AssetCareError
 * @description Custom class made for better tracking and handling errors in the application
 */
export class AssetCareError extends Error {
  /**
   * @param {string} message - The error message
   * @param {number} status - The HTTP status code associated with the error
   * @param {string} [errorOrigin] - Optional origin of the error
   */
  constructor(
    message: string,
    public status: number,
    public errorOrigin?: string
  ) {
    super(message);
  }
}
