import { useDashboardDataContext, useUpdateUrlParam } from '@/hooks';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchDevices, useSort } from '../hooks';
import { DashboardTableBody } from './DashboardTableBody';
import { DashboardTableHead } from './DashboardTableHead';
import { DashboardTableSkeleton } from './DashboardTableSkeleton';
import { NoData } from './NoData';
import { SearchFilterSection } from './SearchFilterSection';

export const DashboardTable: React.FC = () => {
  const { searchedData, filterParamsCount, setSearchTerm, searchTerm } =
    useSearchDevices();
  const { data, isFetching } = useDashboardDataContext();
  const { resetFilters } = useUpdateUrlParam();
  const location = useLocation();

  // Scroll to device details if device id is provided in url
  useEffect(() => {
    if (!location.state?.id) {
      return;
    }

    const element = document.getElementById(location.state.id);

    if (!element) {
      return;
    }

    element.scrollIntoView({
      block: 'nearest',
    });
  }, [location.state?.id]);

  const { sortedDevices, handleSort, sortColumn, sortDirection } =
    useSort(searchedData);

  const handleViewAllClick = () => {
    setSearchTerm('');
    resetFilters();
  };

  const shouldShowNoData = sortedDevices.length === 0 && !isFetching;

  return (
    <>
      <div className="sticky top-66 z-10 mb-16">
        <SearchFilterSection
          maxCount={data?.length ?? 0}
          count={searchedData.length}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filterParamsCount={filterParamsCount}
        />
      </div>
      {shouldShowNoData ? (
        <NoData onViewAllClick={handleViewAllClick} />
      ) : (
        <div className="rounded-4 border-1 border-t-[0px] border-gray-4 py-1">
          <table className="border-collapsed w-full table-fixed rounded-t-4 border-hidden bg-white sm:table-auto">
            <DashboardTableHead
              onSort={handleSort}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
            />
            {isFetching ? (
              <DashboardTableSkeleton />
            ) : (
              <DashboardTableBody sortedDevices={sortedDevices} />
            )}
          </table>
        </div>
      )}
    </>
  );
};
