import { I18N_LANGUAGE } from '@/constants';

/**
 * @description Get current language from local storage
 * @returns The current language
 */
export const getCurrentLanguage = () => {
  const language = localStorage.getItem(I18N_LANGUAGE) || 'en';

  if (language.includes('-')) {
    return language.split('-')[0];
  }

  return language;
};
