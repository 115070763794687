import { Device, DeviceStatus } from '@/@types';

export interface StatusInfo {
  notInUse: number;
  inUse: number;
  errorDetected: number;
  inRepair: number;
  total: number;
}

/**
 * @description Get the number of devices in each status
 * @param devices
 * @returns The object containing the number of devices in each status as well as the total number of devices.
 */
export const getStatusInfo = (devices: Device[]): StatusInfo => {
  const total = devices.length;

  let notInUse = 0;
  let inUse = 0;
  let errorDetected = 0;
  let inRepair = 0;

  devices.forEach((device) => {
    if (device.deviceStatus === DeviceStatus.NOT_IN_USE) {
      notInUse++;
    } else if (device.deviceStatus === DeviceStatus.IN_USE) {
      inUse++;
    } else if (device.deviceStatus === DeviceStatus.ERROR_DETECTED) {
      errorDetected++;
    } else if (device.deviceStatus === DeviceStatus.IN_REPAIR) {
      inRepair++;
    }
  });

  return { notInUse, inUse, errorDetected, inRepair, total };
};
