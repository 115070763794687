import React, { useEffect } from 'react';

/**
 * @description HOC that sets the scroll position to the top of the page before rendering the wrapped component
 * @param {ReactNode} WrappedComponent
 * @returns {ReactNode} EnhancedComponent
 */
export const withScrollToTop = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  const EnhancedComponent = (props: P) => {
    useEffect(() => {
      document.body.scrollTo(0, 0);
    }, []);

    return <WrappedComponent {...props} />;
  };

  return EnhancedComponent;
};
