const MAX_STRING_LENGTH = 9;

/**
 * @description Reduce a string to a certain length
 * @param s
 * @param limit
 * @returns substring of the original string
 */
export const reduceString = (s: string, limit = MAX_STRING_LENGTH) =>
  s?.length > MAX_STRING_LENGTH ? s.substring(0, limit) : s;

/**
 * @description Remove underscores from a string
 * @param s
 * @returns string with underscores removed
 */
export const removeUnderscore = (s: string) =>
  s.replace(/_/g, ' ').toLowerCase();
