import { getConfig, getToken } from '@novax/os';

/**
 * @description This function is used to make authenticated requests to the backend APIs.
 * @param url The URL to make the request to.
 * @param options The options to pass to the fetch function.
 * @returns The response from the API.
 */
export const fetchWithAuth = (
  url: string | URL | globalThis.Request,
  options: RequestInit = {}
) => {
  const token = getToken() ?? '';
  const config = getConfig();
  const tenantId = config?.AUTH_REALM ?? '';

  return fetch(url, {
    ...options,
    headers: {
      Authorization: `Bearer ${token}`,
      'Nova-Tenant-Id': tenantId,
      ...options.headers,
    },
  });
};
